export default {
  computed: {
    createFlowType() {
      const { create = null } = this.$route.query;
      return create;
    },
    isCreateFlow() {
      console.log('is create fl', this.createFlowType);
      return this.createFlowType === 'sab';
    },
    fallbackContent() {
      const newCampaignFlowDescrioptionKey = `newCampaignFlow.codeInsert.${this.createFlowType}.${this.platform}`;
      if (this.$te(newCampaignFlowDescrioptionKey)) return this.$t(newCampaignFlowDescrioptionKey);
      const fallbackKey = `newCampaignFlow.codeInsert.${this.createFlowType}.fallback`;
      return this.$te(fallbackKey) ? this.$t(fallbackKey) : ' ';
    },
    content() {
      const createSpecificDescriptionKey = `codeInsertV2.bigcommerce.${this.createFlowType}`;
      if (this.$te(createSpecificDescriptionKey)) {
        return this.$t(createSpecificDescriptionKey);
      }

      return this.fallbackContent;
    },
  },
};
