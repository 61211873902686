export default {
  props: {
    domain: { type: String, default: null },
    platform: { type: String, default: null },
    campaign: { type: String, default: null },
    step: { type: String, default: null },
  },
  computed: {
    isOnboarding() {
      return !this.$route.name.startsWith('settings');
    },
    setupGuideTrackPrefix() {
      return `insertCode-setupGuide-${this.platform}`;
    },
    illustration() {
      return ['custom', 'other', 'manual'].includes(this.platform) ? 'html' : this.platform;
    },
    defaultStep() {
      return Number(this.step);
    },
  },
  methods: {
    getSetupGuideTrackPrefix(step) {
      return `${this.setupGuideTrackPrefix}-${step}`;
    },
    goBack() {
      this.$router.go(-1);
    },
    navigateToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
    correctPlatform(platform) {
      if (platform === 'woocommerce') return 'wordpress';
      return platform;
    },
  },
};
