import { mapGetters } from 'vuex';
import CHECK_SHOPIFY_APP_EMBED_STATUS from '@/graphql/CheckShopifyAppEmbedStatuses.gql';

export default {
  computed: {
    ...mapGetters(['shopSettingsByDomain', 'domainRequestStatus', 'domains']),
    domainRecentlyRequested() {
      return this.domainRequestStatus(this.domain) === 'accessible';
    },
    shop() {
      return this.shopSettingsByDomain(this.domain);
    },
    myShopifyDomain() {
      return this.shop?.myshopify_domain;
    },
    isShopifyPlatform() {
      return this.platform === 'shopify';
    },
    shopifyAppEmbedEnabled() {
      return this.appEmbedStatuses?.[0]?.disabled === false;
    },
    isSamePlatform() {
      const { platform = 'custom' } =
        this.domains?.find?.(({ domain }) => domain === this.domain) ?? {};
      return this.platform === platform;
    },
    isConnected() {
      if (this.isShopifyPlatform) return this.shopifyAppEmbedEnabled;
      return this.isSamePlatform && this.domainRecentlyRequested;
    },
  },

  apollo: {
    appEmbedStatuses: {
      query: CHECK_SHOPIFY_APP_EMBED_STATUS,
      variables() {
        return {
          myshopifyDomains: [this.myShopifyDomain],
        };
      },
      skip() {
        return !this.isShopifyPlatform || !this.myShopifyDomain;
      },
    },
  },
};
