<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.magento.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.magento.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.0')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_1.jpg')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.1')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_2.jpg')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.2')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_3.jpg')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.3')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_4.jpg')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_4_2.jpg')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.4')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_5.jpg')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.5')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_5_2.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.0.texts.6')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_6.png')")
        img.mt-3.mb-3(
          :src="require('@/assets/admin/img/insert-code/platforms/magento/step_1_7.png')"
        )
    om-accordion-item(
      :step="2"
      :trackPrefix="getSetupGuideTrackPrefix('step2')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.magento.steps.1.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.0')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_1.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.1')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_2.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.2')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_3.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.3')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_4.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.4')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_5.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.5')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_6.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.6')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_7.png')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.7')")
        om-body-text(bt400md v-html="$t('codeInsertV2.magento.steps.1.texts.8')")
        img.mt-3.mb-3(
          :src="require('@/assets/admin/img/insert-code/platforms/magento/step_2_8.png')"
        )
    om-accordion-item(
      :step="2"
      :trackPrefix="getSetupGuideTrackPrefix('step3')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.magento.steps.2.title') }}
      .code-insert-step-indent
        om-body-text.mb-4(bt400md v-html="$t('codeInsertV2.magento.steps.2.texts.0', { userId })")
</template>

<script>
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import { getAccountIdFromCookie } from '@/util';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Magento',
    mixins: [childRouteMixin, domainStatusMixin, platformMixin],
    computed: {
      userId() {
        return getAccountIdFromCookie();
      },
    },
  };
</script>
